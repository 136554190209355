
.#{$prefix}color-dark-blue {
  color: $dark-blue !important;
}

.#{$prefix}color-silver-tfoot {
  color: $silver-tfoot !important;
}


.#{$prefix}d-none {
  display: none !important;
}
.#{$prefix}d-flex {
  display: flex;
}

.#{$prefix}text-bold {
  font-weight: bold;
}

.#{$prefix}mt-0 {
  margin-top: 0px !important;
}

.#{$prefix}mb-6 {
  margin-bottom: 6px !important;
}

.#{$prefix}mb-12{
  margin-bottom: 12px !important;
}

.#{$prefix}mb-25{
  margin-bottom: 25px !important;
}

.#{$prefix}m-auto {
  margin: 0 auto !important;
}

.#{$prefix}pb-40{
  padding-bottom: 40px !important;
}

.#{$prefix}mt-8{
    margin-top: 8px  !important;
}
.#{$prefix}mt-6{
  margin-top: 6px !important;
}
.#{$prefix}ml-24{
  margin-left: 24px !important;
}

.#{$prefix}ml-45{
  margin-left: 45px !important;
}

.#{$prefix}text-center {
  text-align: center  !important;
}

.#{$prefix}error-border {
  border: 1px solid $error !important;
}

.#{$prefix}bg-unset{
  background: unset !important;
}

.#{$prefix}col-3-ie {
  display: -ms-grid !important;
}

.#{$prefix}bg-pos-0 {
  background-position-y: 0 !important;
}
