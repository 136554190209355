

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Arial, sans-serif;
  top: 0;
  margin: 0;
  background: #f2f4f5;
}

input[type="text"] {
  -moz-appearance: textfield !important;
}
input[type="text"]:hover,
input[type="text"]:focus {
  -moz-appearance: text-input;
}

input[type="text"]::-webkit-inner-spin-button,
input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.#{$prefix}container {
  &--relative {
    position: relative;
  }

  margin: 0 auto;
  top: 0;
  background: $container_bg;

  @include xxl {
    width: 1272px;
  }

  h1 {
    margin: 0 auto;
    padding-top: 24px;
    color: $dark-blue;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;

    @include md {
      font-size: 32px;
    }
  }

  .#{$prefix}row > h2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin: 40px 0 24px;
    padding-left: 18px;

    &:before {
      content: "";
      height: 8px;
      width: 8px;
      margin-left: -18px;
      margin-top: 15px;
      background-color: $orange;
      border-radius: 50%;
      display: block;
      position: absolute;
    }
  }

  .#{$prefix}row {
    margin: 0 16px;

    @include md {
      margin: 0 24px;
    }

    @include lg {
      margin: 0 38px;
    }

    @include xl {
      margin: 0 90px;
    }

    @include xxl {
      margin: 0 108px;
    }

    &--hr {
      hr {
        margin-top: -37px;
        border-top: 1px solid $silver;
        margin-bottom: 21px;
      }
    }

    &--switch {
      display: flex;
      padding-left: 16px;

      div {
        margin-right: 16px;

        h4 {
          margin-top: 0px;
          margin-bottom: 10px;
          cursor: pointer;
          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          color: $orange;
        }

        .#{$prefix}active {
          color: $dark-blue;

          &:after {
            position: inherit;
            text-align: center;
            display: block;
            content: "";
            border-top: 2px solid $dark-blue;
            margin-top: 27px;

            width: 100%;
            padding-top: 10px;
            /* margin: 25px; */
            transform: translateY(-1rem);
          }
        }
      }
    }
  }
}

.#{$prefix}form {
  margin-top: 24px;
  background: $form_background;
  border: 1px solid $table_thead_tfoot;
  border-top: 5px solid $orange;
  box-sizing: border-box;
  width: 100%;

  &--container {
    display: grid;
    display: -ms-flexbox;

    .#{$prefix}col-2 {
      padding-right: 35px;
    }

    @include xs {
      .#{$prefix}col-1 {
        padding-left: 20px;
      }

      .#{$prefix}col-2 {
        padding-left: 20px;
      }

      .#{$prefix}col-3 {
        margin-top: 20px;
        margin-bottom: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include sm {
      .#{$prefix}col-1 {
        padding-left: 20px;
        padding-right: 20px;
      }

      .#{$prefix}col-2 {
        padding-left: 20px;
      }

      .#{$prefix}col-3 {
        margin-top: 20px;
        margin-bottom: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include md {
      grid-template-columns: 370px auto;

      .#{$prefix}col-1 {
        padding-left: 24px;
      }

      .#{$prefix}col-2 {
        padding-left: 0px;
      }

      .#{$prefix}col-3 {
        margin-top: 24px;
        margin-bottom: 36px;

        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include lg {
      grid-template-columns: 486px auto;

      .#{$prefix}col-2 {
        padding-left: 0px;
      }

      .#{$prefix}col-3 {
        margin-top: 24px;
        margin-bottom: 36px;
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include xl {
      grid-template-columns: 494px auto;

      .#{$prefix}col-2 {
        padding-left: 0px;
      }

      .#{$prefix}col-3 {
        margin-top: 24px;
        margin-bottom: 36px;
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include xxl {
      grid-template-columns: 540px auto;

      .#{$prefix}col-1 {
        padding-left: 36px;
      }

      .#{$prefix}col-2 {
        padding-left: 0px;
      }

      .#{$prefix}col-3 {
        margin-top: 24px;
        margin-bottom: 36px;
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .#{$prefix}input--wrapper {
      &-wyp {
        display: flex;
        margin-left: 6px;
        margin-top: 6px;

        label {
          padding-top: 7px;
          color: $dark-blue;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 6px;
          width: 140px;
        }

        input {
          margin: 0 !important;
          width: 99px !important;
          background: url('data:image/svg+xml,%3Csvg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.651855 2.91406C0.651855 1.95215 0.861816 1.23926 1.28174 0.775391C1.70654 0.311523 2.28027 0.0795898 3.00293 0.0795898C3.75 0.0795898 4.3335 0.311523 4.75342 0.775391C5.17822 1.23438 5.39062 1.94727 5.39062 2.91406C5.39062 3.87598 5.17822 4.58887 4.75342 5.05273C4.3335 5.5166 3.76221 5.74854 3.03955 5.74854C2.29248 5.74854 1.70654 5.51904 1.28174 5.06006C0.861816 4.59619 0.651855 3.88086 0.651855 2.91406ZM2.25586 2.89941C2.25586 3.59766 2.33643 4.06641 2.49756 4.30566C2.61963 4.48145 2.78809 4.56934 3.00293 4.56934C3.22266 4.56934 3.39355 4.48145 3.51562 4.30566C3.67188 4.06641 3.75 3.59766 3.75 2.89941C3.75 2.20117 3.67188 1.73486 3.51562 1.50049C3.39355 1.31982 3.22266 1.22949 3.00293 1.22949C2.78809 1.22949 2.61963 1.31738 2.49756 1.49316C2.33643 1.73242 2.25586 2.20117 2.25586 2.89941ZM4.57031 11.4102H3.04688L8.76709 0.0795898H10.2466L4.57031 11.4102ZM7.89551 8.59033C7.89551 7.62842 8.10547 6.91553 8.52539 6.45166C8.9502 5.98779 9.52881 5.75586 10.2612 5.75586C10.9985 5.75586 11.5771 5.98779 11.9971 6.45166C12.4219 6.91553 12.6343 7.62842 12.6343 8.59033C12.6343 9.55713 12.4219 10.2725 11.9971 10.7363C11.5771 11.2002 11.0059 11.4321 10.2832 11.4321C9.53613 11.4321 8.9502 11.2002 8.52539 10.7363C8.10547 10.2725 7.89551 9.55713 7.89551 8.59033ZM9.49951 8.58301C9.49951 9.28125 9.58008 9.74756 9.74121 9.98193C9.86328 10.1626 10.0317 10.2529 10.2466 10.2529C10.4663 10.2529 10.6348 10.165 10.752 9.98926C10.9131 9.75 10.9937 9.28125 10.9937 8.58301C10.9937 7.88477 10.9155 7.41602 10.7593 7.17676C10.6372 7.00098 10.4663 6.91309 10.2466 6.91309C10.0269 6.91309 9.8584 7.00098 9.74121 7.17676C9.58008 7.41602 9.49951 7.88477 9.49951 8.58301Z" fill="%23052144"/%3E%3C/svg%3E%0A') 88% / 14% 15px no-repeat #fff !important;
        }
      }

      &-ppk {
        margin-left: 24px;
        margin-bottom: 6px;
        display: flex;

        > div {
          input {
            background: url('data:image/svg+xml,%3Csvg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.651855 2.91406C0.651855 1.95215 0.861816 1.23926 1.28174 0.775391C1.70654 0.311523 2.28027 0.0795898 3.00293 0.0795898C3.75 0.0795898 4.3335 0.311523 4.75342 0.775391C5.17822 1.23438 5.39062 1.94727 5.39062 2.91406C5.39062 3.87598 5.17822 4.58887 4.75342 5.05273C4.3335 5.5166 3.76221 5.74854 3.03955 5.74854C2.29248 5.74854 1.70654 5.51904 1.28174 5.06006C0.861816 4.59619 0.651855 3.88086 0.651855 2.91406ZM2.25586 2.89941C2.25586 3.59766 2.33643 4.06641 2.49756 4.30566C2.61963 4.48145 2.78809 4.56934 3.00293 4.56934C3.22266 4.56934 3.39355 4.48145 3.51562 4.30566C3.67188 4.06641 3.75 3.59766 3.75 2.89941C3.75 2.20117 3.67188 1.73486 3.51562 1.50049C3.39355 1.31982 3.22266 1.22949 3.00293 1.22949C2.78809 1.22949 2.61963 1.31738 2.49756 1.49316C2.33643 1.73242 2.25586 2.20117 2.25586 2.89941ZM4.57031 11.4102H3.04688L8.76709 0.0795898H10.2466L4.57031 11.4102ZM7.89551 8.59033C7.89551 7.62842 8.10547 6.91553 8.52539 6.45166C8.9502 5.98779 9.52881 5.75586 10.2612 5.75586C10.9985 5.75586 11.5771 5.98779 11.9971 6.45166C12.4219 6.91553 12.6343 7.62842 12.6343 8.59033C12.6343 9.55713 12.4219 10.2725 11.9971 10.7363C11.5771 11.2002 11.0059 11.4321 10.2832 11.4321C9.53613 11.4321 8.9502 11.2002 8.52539 10.7363C8.10547 10.2725 7.89551 9.55713 7.89551 8.59033ZM9.49951 8.58301C9.49951 9.28125 9.58008 9.74756 9.74121 9.98193C9.86328 10.1626 10.0317 10.2529 10.2466 10.2529C10.4663 10.2529 10.6348 10.165 10.752 9.98926C10.9131 9.75 10.9937 9.28125 10.9937 8.58301C10.9937 7.88477 10.9155 7.41602 10.7593 7.17676C10.6372 7.00098 10.4663 6.91309 10.2466 6.91309C10.0269 6.91309 9.8584 7.00098 9.74121 7.17676C9.58008 7.41602 9.49951 7.88477 9.49951 8.58301Z" fill="%23052144"/%3E%3C/svg%3E%0A') 88% / 14% 15px no-repeat #fff !important;
          }

          label,
          input {
            width: 99px;
            margin-bottom: 0px;
          }
        }
      }

      .#{$prefix}container--switch {
        margin-top: 12px;
        margin-bottom: 24px;
        display: flex;

        @include sm {
          margin-bottom: 20px;
        }

        > span {
          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          color: $dark-blue;
        }

        .#{$prefix}tooltip {
          margin-left: 12px;
        }

        label {
          margin-top: 2px;
          position: relative;
          display: inline-block;
          width: 32px;
          height: 16px;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          span {
            /* The slider */
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            -webkit-transition: 0.4s;
            transition: 0.4s;
            background: #fff;
            border: 1px solid rgba(5, 33, 68, 0.2);
            box-sizing: border-box;
            border-radius: 34px;

            &:before {
              position: absolute;
              content: "";
              height: 10px;
              width: 10px;
              left: 2px;
              bottom: 2px;
              background: $selected_radio;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              border-radius: 50%;
            }
          }

          input:checked + .#{$prefix}slider:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
          }
        }

        .#{$prefix}span-left {
          margin-right: 8px;
        }

        .#{$prefix}span-right {
          margin-left: 8px;
        }
      }
    }
  }

  h2 {
    margin: 36px 36px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $dark-blue;
  }

  @include xs {
    h2 {
      margin: 20px 20px 20px;
    }
  }

  @include sm {
    h2 {
      margin: 20px 20px 20px;
    }
  }

  @include xxl {
    h2 {
      margin: 36px 36px 24px;
    }
  }

  h2::before {
    content: ".";
    color: $orange;
    margin-right: 1px;
    font-size: 24px;
  }

  label {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: $label_color;
  }

  input[type="text"],
  select {
    margin: 6px 6px 24px 0;
    color: $dark-blue;
    padding-left: 12px;
    font-style: normal;
    font-weight: bold;

    height: 32px;
    border: 1px solid rgba(5, 33, 68, 0.2);
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 15px;
    line-height: 17px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  @include xs {
    select {
      width: 86%;
      background:  97% / 3% no-repeat #fff;
    }

    input[type="text"] {
      width: 86%;
      background: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.411621 11V9.396L3.32666 6.04883C3.80518 5.50195 4.15918 5.11377 4.38867 4.88428C4.14941 4.89893 3.83447 4.90869 3.44385 4.91357L0.697266 4.92822V3.22168H7.12793V4.6792L4.1543 8.10693L3.10693 9.24219C3.67822 9.20801 4.03223 9.19092 4.16895 9.19092H7.35498V11H0.411621ZM8.73926 5.72656V0.262695H10.7974V3.97607L11.8008 3.12646V4.51807L10.7974 5.36768V11H8.73926V7.11816L7.73584 7.96777V6.57617L8.73926 5.72656Z' fill='%23052144'/%3E%3C/svg%3E%0A") 97% / 3% 12px no-repeat #fff;
    }
  }

  @include sm {
    input[type="text"],
    select {
      margin: 6px 6px 20px 0;
    }

    input[type="text"] {
      width: 89%;
      background: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.411621 11V9.396L3.32666 6.04883C3.80518 5.50195 4.15918 5.11377 4.38867 4.88428C4.14941 4.89893 3.83447 4.90869 3.44385 4.91357L0.697266 4.92822V3.22168H7.12793V4.6792L4.1543 8.10693L3.10693 9.24219C3.67822 9.20801 4.03223 9.19092 4.16895 9.19092H7.35498V11H0.411621ZM8.73926 5.72656V0.262695H10.7974V3.97607L11.8008 3.12646V4.51807L10.7974 5.36768V11H8.73926V7.11816L7.73584 7.96777V6.57617L8.73926 5.72656Z' fill='%23052144'/%3E%3C/svg%3E%0A") 97% / 3% 12px no-repeat #fff;
    }
  }

  @include md {
    select {
      width: 168px;
      background: $arrow_down 93% / 10% no-repeat #fff;
    }
    input[type="text"] {
      width: unset;
    }
  }

  @include xl {
    select {
      width: 168px;
      background: $arrow_down 93% / 10% no-repeat #fff;
    }

    input[type="text"] {
      background: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.411621 11V9.396L3.32666 6.04883C3.80518 5.50195 4.15918 5.11377 4.38867 4.88428C4.14941 4.89893 3.83447 4.90869 3.44385 4.91357L0.697266 4.92822V3.22168H7.12793V4.6792L4.1543 8.10693L3.10693 9.24219C3.67822 9.20801 4.03223 9.19092 4.16895 9.19092H7.35498V11H0.411621ZM8.73926 5.72656V0.262695H10.7974V3.97607L11.8008 3.12646V4.51807L10.7974 5.36768V11H8.73926V7.11816L7.73584 7.96777V6.57617L8.73926 5.72656Z' fill='%23052144'/%3E%3C/svg%3E%0A") 93% / 10% 12px no-repeat #fff;
    }
  }

  @include xxl {
    select {
      width: 168px;
      background: $arrow_down 93% / 10% no-repeat #fff;
    }

    input[type="text"] {
      background: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.411621 11V9.396L3.32666 6.04883C3.80518 5.50195 4.15918 5.11377 4.38867 4.88428C4.14941 4.89893 3.83447 4.90869 3.44385 4.91357L0.697266 4.92822V3.22168H7.12793V4.6792L4.1543 8.10693L3.10693 9.24219C3.67822 9.20801 4.03223 9.19092 4.16895 9.19092H7.35498V11H0.411621ZM8.73926 5.72656V0.262695H10.7974V3.97607L11.8008 3.12646V4.51807L10.7974 5.36768V11H8.73926V7.11816L7.73584 7.96777V6.57617L8.73926 5.72656Z' fill='%23052144'/%3E%3C/svg%3E%0A") 93% / 10% 12px no-repeat #fff;
    }
  }

  button {
    cursor: pointer;
    height: 36px;
    background-color: $dark-blue;
    color: #fff;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    display: block;

    @include xs {
      width: 282px;
    }

    @include sm {
      width: 504px;
    }

    @include md {
      width: 322px;
    }

    @include lg {
      width: 344px;
    }

    @include xl {
      width: 344px;
    }

    @include xxl {
      width: 344px;
    }

    > span {
      &:before {
        content: "";
        width: 20px;
        padding-right: 30px;
        background-image: $btn_icon;
        background-repeat: no-repeat;
      }
    }
  }

  .#{$prefix}check-label {
    cursor: pointer;
    margin-left: 24px;
    margin-top: 12px;
    color: $dark-blue;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    display: inline;
    clear: both;
  }

  .#{$prefix}tooltip {
    background-image: $tooltip_img;
    background-repeat: no-repeat;
    padding-right: 17px;
    cursor: pointer;
    filter: #097BC4;
    background-position-y: 7px;
  }
}

.#{$prefix}container--checkbox {
  display: block;
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &--mb12 {
    margin-bottom: 12px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .#{$prefix}checkmark {
    cursor: pointer;
  }

  .#{$prefix}tooltip {
    margin-left: 16px;
    padding-bottom: 1px;
  }

  .#{$prefix}checkmark,
  .#{$prefix}checkmark-dis {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #fff;
    border: 1px solid rgba(5, 33, 68, 0.2);
    box-sizing: border-box;
    margin-top: 7px;
  }

  .#{$prefix}checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .#{$prefix}checkmark:after {
    display: block;
  }

  input:checked ~ .#{$prefix}checkmark-dis:after {
    display: block;
  }

  .#{$prefix}checkmark:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid $check_mark_color;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .#{$prefix}checkmark-dis:after {
    border: solid silver;
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.#{$prefix}radio--container {
  margin-top: 12px;
  margin-bottom: 25px;

  > label {
    display: inline-block;
    color: $dark-blue;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;

    margin-right: 16px;
    margin-bottom: 13px;
  }
}

.#{$prefix}radio .#{$prefix}label {
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: $radio_border;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
}

.#{$prefix}radio .#{$prefix}label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: $selected_radio;
  transform: scale(0);
  transition: all 0.2s ease;
  opacity: 0.08;
  pointer-events: none;
}

.#{$prefix}radio:hover .#{$prefix}label:after {
  transform: scale(3.6);
}

input[type="radio"]:checked + .#{$prefix}label:after {
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}

.#{$prefix}hidden {
  display: none;
}

.#{$prefix}radio-text {
  color: $dark-blue;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
}

%tooltip-content {
  min-width: 168px;
  content: attr(#{$prefix}data-tooltip);
  font-style: normal;
  z-index: -4;
  font-weight: normal;
  font-size: 11px;
  position: absolute;
  line-height: 17px;
  opacity: 0;
  max-width: 280px;
  transition: all 0.15s ease;
  padding: 12px;
  color: $tooltip_text;
  background: #ffffff;
  border: 1px solid $border_tooltip;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-left: 10px;
}

[#{$prefix}data-tooltip]:before {
  @extend %tooltip-content;
  content: attr(#{$prefix}data-tooltip);
  @include sm-max {
    left: 10%;
    margin-top: 5px;
  }
}

.#{$prefix}tooltip--special:before {
  @include md-max {
    right: 15%;
    margin-top: 5px;
  }
}

[#{$prefix}data-tooltip]:hover {
  &:before {
    z-index: 444;
    opacity: 1;
    display: unset;
  }
}

@include xs {
  .#{$prefix}row--table::before {
    position: absolute;
    height: 100%;
    content: "";
    width: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='832' viewBox='0 0 16 832' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='832' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.1242e-08' y1='365' x2='16' y2='365' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }

  .#{$prefix}row--table::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 20px;
    right: 10px;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='832' viewBox='0 0 16 832' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='832' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.1242e-08' y1='365' x2='16' y2='365' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='white'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

@include sm {
  .#{$prefix}row--table::before {
    height: 100%;
    content: "";
    width: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='832' viewBox='0 0 16 832' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='832' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.1242e-08' y1='365' x2='16' y2='365' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }

  .#{$prefix}row--table::after {
    content: "";
    height: 100%;
    right: 10px;
    width: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='832' viewBox='0 0 16 832' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='832' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.1242e-08' y1='365' x2='16' y2='365' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='white'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

@include md {
  .#{$prefix}row--table::before,
  .#{$prefix}row--table::after {
    background-image: unset;
    display: none;
  }
}

.#{$prefix}error {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: $error;

  &-msg {
    &::before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      margin-left: -21px;
      background-image: $error_icon;
      background-repeat: no-repeat;
    }
  }
}

.ecv_calc_bg-unset::after {
  content: "";
  position: absolute;
  display: none;
  border: $silver;
  border-width: 0 3px 3px 0;
}

select::-ms-expand {
  display: none;
}

input[type="text"]::-ms-clear {
  display: none;
}
