.#{$prefix}row--table {
  overflow-x: auto;
}

table {
  float: left;
  width: 100%;
  border-spacing: 0;

  td {
    width: 60px;
    padding-left: 10px;
    padding-right:  10px;
    max-height: 55px;
    white-space: nowrap;
  }

  th {
    padding-left: 10px;
    padding-right: 10px;
  }

  .#{$prefix}line {
    border-bottom: 1px solid $table_thead_tfoot;
  }


  tr > td:last-child {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $dark-blue !important;
  }

  .#{$prefix}tbody {
    tr > td:nth-child(n + 3):nth-child(-n + 8) {
      background: $table_background !important;
    }

    tr > td:nth-child(n + 8):nth-child(-n + 15) {
      background: $table_background !important;
    }

    .#{$prefix}new-color > td:nth-child(n + 3):nth-child(-n + 8) {
        background: $table_background_secondary !important;
      }

    .#{$prefix}new-color > td:nth-child(n + 8):nth-child(-n + 15) {
        background: $table_background_secondary !important;
      }



    td {
      border-bottom: 1px solid $table_thead_tfoot;
    }
  }

  .#{$prefix}thead tr {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    height: 37.5px;
    background: $table_thead_tfoot;
    color: $thead_tr;
  }

  .#{$prefix}tbody {

    tr {
      height: 56px;
      max-height: 56px;
      text-align: center;
      color: $tr_color;
    }

    td{

    }
  }
  input {
    color: $dark-blue;
    padding-left: 12px;
    font-style: normal;
    font-weight: bold;
    width: 84px;
    height: 32px;
    border: 1px solid rgba(5, 33, 68, 0.2);
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 15px;
    line-height: 17px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .#{$prefix}tfoot tr {
    color: $tr_color;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    height: 56px;
    background: $table_thead_tfoot;
    text-align: center;
  }
}
