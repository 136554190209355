
$screen-xs-min: 310px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 948px;
$screen-xl-min: 975px;
$screen-xxl-min: 1272px;


@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}


@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
